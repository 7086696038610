import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd"  transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">


<path d="M5300 9534 c-157 -35 -309 -150 -384 -293 -74 -141 -92 -275 -56
-415 43 -170 155 -308 310 -384 94 -47 159 -62 261 -62 246 0 464 156 551 396
30 81 36 237 14 324 -28 108 -74 187 -157 271 -147 149 -339 207 -539 163z"/>
<path d="M4640 8359 c-444 -43 -871 -261 -1167 -598 -185 -211 -324 -471 -393
-736 -88 -342 -74 -701 41 -1039 90 -266 224 -481 424 -682 267 -269 596 -440
970 -506 158 -28 429 -29 590 -3 212 33 445 116 623 221 452 267 771 725 863
1242 28 155 30 449 5 595 -47 271 -139 503 -282 718 -367 549 -1010 852 -1674
788z m-735 -1083 c120 -35 204 -85 296 -176 70 -69 94 -101 128 -170 97 -200
96 -417 -5 -618 -79 -158 -213 -270 -389 -329 -77 -26 -90 -27 -342 -31 l-263
-4 0 677 0 677 258 -5 c205 -3 269 -8 317 -21z m2135 -111 l0 -135 -235 0
-235 0 0 -135 0 -135 215 0 215 0 0 -135 0 -135 -215 0 -215 0 0 -270 0 -270
-155 0 -155 0 0 675 0 675 390 0 390 0 0 -135z m-980 -740 l0 -125 -262 2
-263 3 -1 115 c-1 63 1 118 3 123 2 4 121 7 264 7 l259 0 0 -125z"/>
<path d="M3640 6628 c0 -217 2 -397 5 -399 2 -3 39 -2 83 2 94 8 159 38 225
104 153 152 153 427 1 579 -66 66 -139 98 -235 104 l-79 5 0 -395z"/>
<path d="M6905 7071 c-79 -20 -140 -76 -171 -156 -15 -41 -16 -55 -6 -103 15
-71 53 -126 111 -160 38 -23 58 -27 116 -27 63 1 75 4 117 33 122 84 141 245
41 347 -54 56 -140 83 -208 66z"/>
<path d="M5408 4310 c-81 -20 -169 -100 -188 -170 -37 -133 22 -226 197 -310
94 -45 133 -78 133 -113 0 -89 -144 -103 -266 -26 -24 16 -49 29 -53 29 -7 0
-72 -103 -89 -142 -6 -13 92 -68 158 -89 79 -24 203 -26 275 -5 63 19 132 77
156 130 21 46 25 139 8 183 -21 57 -89 113 -199 165 -117 55 -150 81 -150 115
0 62 79 87 173 54 34 -12 70 -24 79 -27 13 -5 25 10 52 64 l35 70 -22 15 c-73
47 -224 76 -299 57z"/>
<path d="M6146 4309 c-125 -20 -241 -112 -299 -237 -26 -56 -32 -82 -35 -158
-8 -185 67 -323 222 -405 54 -29 71 -33 164 -37 94 -4 109 -2 170 22 117 46
201 135 242 257 18 53 21 81 18 159 -3 81 -8 105 -35 162 -59 126 -170 213
-302 238 -60 11 -75 11 -145 -1z m171 -196 c43 -20 89 -81 104 -137 16 -59 6
-165 -21 -220 -35 -72 -78 -100 -164 -104 -94 -4 -148 22 -191 92 -28 46 -30
56 -30 145 0 87 3 101 28 143 15 26 40 56 55 67 61 43 147 49 219 14z"/>
<path d="M2400 3891 l0 -411 134 0 c73 0 156 4 185 10 120 23 236 109 285 215
102 215 18 459 -192 558 -66 31 -70 32 -239 35 l-173 4 0 -411z m268 238 c60
-12 124 -63 153 -121 81 -159 -26 -357 -193 -358 l-48 0 0 238 c0 131 3 242 7
245 9 9 24 9 81 -4z"/>
<path d="M3218 3908 c-87 -216 -160 -401 -164 -410 -6 -16 3 -18 93 -18 l100
0 27 79 c15 44 33 81 39 84 7 2 75 5 153 6 106 2 143 -1 151 -11 5 -7 22 -46
37 -85 l28 -73 99 0 99 0 -21 53 c-11 28 -61 153 -111 277 -50 124 -114 284
-143 355 l-52 130 -89 3 -89 2 -157 -392z m292 23 c22 -56 40 -107 40 -112 0
-5 -40 -9 -89 -9 l-89 0 33 88 c18 48 38 100 44 116 7 16 14 27 16 24 3 -3 23
-51 45 -107z"/>
<path d="M3810 4220 l0 -80 100 0 100 0 0 -330 0 -330 95 0 96 0 -1 313 c0
171 0 320 0 330 0 15 11 17 95 17 l95 0 0 80 0 80 -290 0 -290 0 0 -80z"/>
<path d="M4498 3908 c-87 -216 -160 -401 -164 -410 -6 -16 3 -18 93 -18 l100
0 27 79 c15 44 33 81 39 84 7 2 75 5 153 6 106 2 143 -1 151 -11 5 -7 22 -46
37 -85 l28 -73 99 0 99 0 -21 53 c-11 28 -61 153 -111 277 -50 124 -114 284
-143 355 l-52 130 -89 3 -89 2 -157 -392z m292 23 c22 -56 40 -107 40 -112 0
-5 -40 -9 -89 -9 l-89 0 33 88 c18 48 38 100 44 116 7 16 14 27 16 24 3 -3 23
-51 45 -107z"/>
<path d="M6720 3890 l0 -410 90 0 90 0 0 165 0 165 135 0 135 0 0 80 0 79
-132 3 -133 3 0 80 0 80 238 3 237 2 0 -330 0 -331 93 3 92 3 3 328 2 327 95
0 95 0 0 80 0 80 -520 0 -520 0 0 -410z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
